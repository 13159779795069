import { getUserSchedule, getTeamSchedule } from '@/utils/api/scheduleActions'
import { startOfISOWeek, addDays } from 'date-fns'

const scheduleWeekStart = startOfISOWeek(new Date())
const scheduleWeekEnd = addDays(scheduleWeekStart, 7)

export const useWorkActivitySchedule = async () => {
  const workActivitySchedule = useState<{
    userSchedule: Awaited<ReturnType<typeof getUserSchedule>>
    teamSchedule: Awaited<ReturnType<typeof getTeamSchedule>>
  }>('workActivitySchedule')
  const { refresh, error, status } = await useAsyncData(
    'myComeen-workActivitySchedule',
    async () => {
      const [userSchedule, teamSchedule] = await Promise.all([
        getUserSchedule('me', scheduleWeekStart, scheduleWeekEnd),
        getTeamSchedule('me', scheduleWeekStart, scheduleWeekEnd, {
          favoriteColleagues: true,
        }),
      ])
      workActivitySchedule.value = { userSchedule, teamSchedule }

      return { userSchedule, teamSchedule }
    }
  )

  return { schedule: workActivitySchedule, refresh, error, status }
}
